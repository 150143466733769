<template>
    <div class="statistics-cinema container">
        <PageHeader
            class="title"
            :title="$t('Home page')"
        />
        <div class="table" >
            <TableSecondary
                :items="statistics"
                :headerTitle="$t('Statistics on cinemas/networks')"
                :itemsMeta="meta"
                @toggle="toggleNetwork"
                :header-columns="columns"
                scroll="md"
                :selectInfo="selectOptions"
                @getItems="getData"
                :preloader="preloader"
                @getSelectValue="getSelectValue"
            />
        </div>
    </div>
</template>

<script>
import SelectComponent from "../../components/ui/forms/SelectComponent";
import PageHeader from "../../components/PageHeader";
import TableSecondary from "../../components/ui/tables/TableSecondary";
import Preloader from "../../components/ui/Preloader";

export default {
    name: "StatisticsNetwork",
    components: {
        SelectComponent,
        PageHeader,
        TableSecondary,
        Preloader
    },
    data() {
        return {
            selectValue: null,
            openNetworkIds: [],
            selectOptions: {
                defaultValue: 1,
                options: [
                    {id: 1, label: this.$t('Yesterday')},
                    {id: 2, label: this.$t('This week')},
                    {id: 3, label: this.$t('This month')},
                    {id: 4, label: this.$t('Last week')},
                    {id: 5, label: this.$t('Last month')},
                ]
            },
            format: 'month',
            dateFrom: '01-09-1990',
            dateTo: '',
            meta: [],
            preloader: true,
            columns: [
                {
                    id: 1,
                    label: this.$t('Cinema'),
                    sort: false,
                    attribute: "name",
                },
                {
                    id: 2,
                    label: this.$t('Tickets'),
                    sort: false,
                    attribute: "count",
                },
                {
                    id: 3,
                    label: this.$t('Sum'),
                    sort: false,
                    attribute: "sum",
                },
            ],
            networkList: [],
            page: 1,
            data: [
                {
                    id: 1,
                    custom_id: "1817-68-07051-4",
                    name: "Gayle Erdman V",
                    token: "JnMSvcZ101oyE1I7td2BGyAFZEztlvg1ps1jeZlF",
                    is_deleted: false,
                    created_at: "2021-09-24T08:37:56.000000Z",
                    updated_at: "2021-09-24T08:37:56.000000Z",
                    stats: {
                        tickets_amount: 4340,
                        tickets_count: 34,
                    },
                    cinemas: [
                        {
                            id: 1,
                            name: '2Test cinema',
                            stats: {
                                tickets_amount: 1239,
                                tickets_count: 213,
                            }

                        },
                        {
                            id: 32,
                            name: 'Test cinema33',
                            stats: {
                                tickets_amount: 1239,
                                tickets_count: 213,
                            }

                        },{
                            id: 43,
                            name: 'Test cinema 43',
                            stats: {
                                tickets_amount: 1239,
                                tickets_count: 213,
                            }

                        }
                    ]
                },
                {
                    id: 2,
                    custom_id: "2593-12-48435-5",
                    name: "Vivian Wunsch",
                    token: "oj8DLYH27xtRkcvj3VDABZTB9xFLXFmtkJrNkg3Q",
                    is_deleted: false,
                    created_at: "2021-09-24T08:37:56.000000Z",
                    updated_at: "2021-09-24T08:37:56.000000Z",
                    stats: {
                        tickets_amount: 32333,
                        tickets_count: 433,
                    },
                    cinemas: [
                        {
                            id: 12,
                            name: 'Test cinema23',
                            stats: {
                                tickets_amount: 1239,
                                tickets_count: 213,
                            }
                        },
                        {
                            id: 22,
                            name: 'Test cinema23',
                            stats: {
                                tickets_amount: 1239,
                                tickets_count: 213,
                            }
                        }
                    ]
                },
            ],
            statistics: [],
            scrolledToBottom: false
        }
    },
    methods: {
        toggleNetwork(id) {
            let index = this.openNetworkIds.indexOf(id);
            if(index !== -1) {
                this.openNetworkIds.splice(index, 1);
            }else{
                this.openNetworkIds.push(+id);
            }
            this.sortData();
        },
        async getData(){
            this.preloader = true
            this.page = this.$route.query.page
            let resp =  await this.api.statistics.getNetworkStatistics(this.format, this.dateFrom, this.dateTo, this.page)
            this.meta = resp
            this.data = resp.data
            this.sortData()
            this.preloader = false
        },
        getSelectValue(value){
            let dateNow = new Date()

            if (value == 1){
                this.dateFrom = this.helper.getYesterdayDate(dateNow)
                this.dateTo = this.helper.getDate(dateNow)

            } else if (value == 2){
                this.dateFrom = this.helper.getCurrentWeek(dateNow)
                this.dateTo = this.helper.getDate(dateNow)

            } else if (value == 3){
                this.dateFrom = this.helper.getCurrentMonth(dateNow)
                this.dateTo = this.helper.getDate(dateNow)

            } else if (value == 4){
                this.dateFrom = this.helper.getStartPreviousWeek(dateNow)
                this.dateTo = this.helper.getEndPreviousWeek(dateNow)

            } else if (value == 5){
                this.dateFrom = this.helper.getStartPreviousMonth(dateNow)
                this.dateTo = this.helper.getEndPreviousMonth(dateNow)
            }
            this.selectOptions.defaultValue = value
            this.getData()
        },
        sortData(){
            let data = [];
            this.data.forEach(network => {
                data.push({
                    haveParent: true,
                    name: network.name,
                    id: network.id,
                    sum: network.stats.tickets_amount,
                    count: network.stats.tickets_count,
                    is_open: this.openNetworkIds.includes(network.id),
                    group: true,
                    cinemasCount: network.cinemas ? network.cinemas.length : 0
                });

                if(!network.cinemas) {
                    return false;
                }

                network.cinemas.forEach(cinema => {
                    data.push({
                        haveParent: true,
                        name: cinema.name,
                        id: cinema.id,
                        sum: cinema.stats.tickets_amount,
                        parent_id: network.id,
                        show: this.openNetworkIds.includes(network.id),
                        count: cinema.stats.tickets_count
                    });
                });
            });

            this.statistics = data;
        }
    },
    created() {
        this.dateFrom = this.helper.getYesterdayDate(new Date())
        this.dateTo = this.helper.getDate(new Date())
        this.getData()
    },
}
</script>

<style scoped lang="scss">
.statistics-network{
    min-height: 80vh;

}
::v-deep{
    .secondary-table{
        .table{
            .table__labelTr {
                th:first-child{
                    width: 82%!important;
                }
                th:last-child{
                    padding-right: 130px;
                }
            }
        }
        .v-select{
            height: 40px;
        }
        .vs__dropdown-toggle{
            position: relative;
            bottom: 3px;
        }
        .vs--searchable .vs__dropdown-toggle, .vs__search{
            cursor: pointer;
        }
    }
    .title{
        margin-top: 30px;
    }

}
</style>
